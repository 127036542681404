<template>
  <div :class="`my-container flex ${active ? 'active' : ''}`">
    <label :for="id" :class="`label ${active || value != '' ? 'top' : ''}`">
      {{ label }}
    </label>
    <input
      @focus="active = true"
      @blur="active = false"
      :id="id"
      :type="inputType"
      :class="`block ${hasIcon ? 'small' : 'big'} focus:outline-none`"
      v-model="value"
      @keyup="valued($event)"
    />
    <slot name="append-icon" />
  </div>
</template>

<script>
export default {
  name: "InputText",
  data() {
    return {
      id: "",
      active: false,
      value: "",
    };
  },
  methods: {
    valued(e) {
      this.$emit("input", this.value);
      if(e.key === 'Enter') {
         this.$emit("login")
      }
    },
  },
  created() {
    this.id = `input-${Math.random()}`;
  },
  props: {
    inputType: {
      type: String,
      required: true,
      default: "text",
    },
    label: {
      type: String,
      required: true,
    },
    hasIcon: Boolean,
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/imports/colors.scss";
.my-container {
  // width: 326px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-bottom: 30px;
  .label {
    // font-family: Manrope;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    color: #cccccc;
    margin-top: 13px;
    padding: 0 6px;
    margin-left: 16px;
    background-color: #ffffff;
    transform-origin: top left;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    &.top {
      margin-top: -11px;
      color: $primary;
    }
  }
  input {
    width: 100%;
    padding-left: 16px;
    &.small {
      width: 85%;
    }
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    /* identical to box height */

    color: #032b3d;
  }
  &.active {
    border-color: $primary;
    i {
      color: $primary;
    }
  }
}
</style>