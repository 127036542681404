<template>
  <div class="back text-right mt-3">
    <router-link class="text-sm" :to="linkRoute">{{ text }}</router-link>
  </div>
</template>

<script >
export default {
  name: "BottomLink",
  props: {
    text: {
      type: String,
      required: true,
    },
    linkRoute: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.back {
  color: #4296bd;
  text-decoration: underline;
  &:hover {
    color: #28a4e2;
  }
}
</style>