<template>
  <button
    type="submit"
    class="w-full text-center py-3 bg-green text-white hover:bg-green-dark focus:outline-none my-1"
  >
    {{ btnText }}
  </button>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    btnText: {
      type: String,
      required: true,
      default: "Submit",
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/imports/colors.scss";
button {
  font-weight: 400;
  background-color: $primary !important;
  color: #ffffff;
  &:hover {
    background-color: #28a4e2c7;
  }
}
</style>