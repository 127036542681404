<template>
  <div class="login-form-page min-h-screen flex flex-col">
    <div
      class="container mx-auto flex-1 flex flex-col items-center justify-center px-2"
    >
      <div class="bg-white rounded shadow-md text-black w-full">
        <img
          src="../../assets/Coat_of_arms_of_Rwanda.png"
          alt="Rwanda National Coat of Arms"
          class="mx-auto"
        />
        <h1 class="text-center">
          {{ title }}
        </h1>
        <slot name="form-content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormStructure",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.login-form-page {
  background: #1679a8;
  font-family: Poppins, sans-serif;
  .container {
    width: 100%;
    max-width: 402px;
    margin-top: 33px;
    height: 524px;
    .rounded {
      background-color: #ffffff;
      padding: 30px 38px;
      h1 {
        margin-top: 30px;
        margin-bottom: 47px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-align: center;

        color: #032b3d;
      }
    }
  }
}
</style>