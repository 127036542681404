<template>
  <FormStructure
    :title="'Enter the verification code which was sent to ' + email"
  >
    <template v-slot:form-content>
      <div>
        <InputText
          :input-type="'text'"
          :label="'Verification code'"
          @input="setCode"
        />
      </div>

      <div>
        <Button :btn-text="'Send'" @click="verify()" />
      </div>

      <BottomLink :link-route="'/login'" :text="'Back to login?'" />
    </template>
  </FormStructure>
</template>

<script>
import Button from "/src/components/auth/Button.vue";
import InputText from "/src/components/auth/InputText.vue";
import BottomLink from "/src/components/auth/BottomLink.vue";
import FormStructure from "/src/components/auth/FormStructure.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VerifPassword",
  components: {
    Button,
    InputText,
    BottomLink,
    FormStructure,
  },
  data: () => {
    return {
      vercode: "",
    };
  },
  computed: {
    ...mapGetters("auth", { email: "email" }),
  },
  methods: {
    ...mapActions({ verifycode: "auth/verifycode" }),
    setCode(value) {
      this.vercode = value;
    },
    verify() {
      if (this.vercode != "") {
        this.verifycode(this.vercode);
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Verification code found",
          uptime: 3000,
        });
      } else {
        //validate
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Field is required",
          uptime: 3000,
          hasError: true,
        });
      }
    },
  },
};
</script>

<style>
</style>